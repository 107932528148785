import type {
	IAggregratedBank,
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IFlashNoteMsg,
	ISettings,
	ISettingsObjKey,
	IUserReferral,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const siteMgtApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateSettings: builder.mutation<IApiResponse<ISettings>, { key: ISettingsObjKey; value: unknown }>({
			query: ({ key, value }) => ({ url: '/admin/settings/update', body: { [key]: value }, method: 'PUT' }),
			invalidatesTags: ['User'],
		}),
		getAggregatedBanks: builder.query<IApiResponsePaginated<IAggregratedBank[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/banks/aggregate', params }),
			providesTags: ['GetAggregatedBanks'],
		}),
		updateAggregatedBank: builder.mutation<IApiResponse, { enable: boolean; bankId: string }>({
			query: ({ enable, bankId }) => ({ url: `/admin/payments/bank/${bankId}/update`, method: enable ? 'PATCH' : 'PUT' }),
			invalidatesTags: ['GetAggregatedBanks'],
		}),
		updateReferral: builder.mutation<IApiResponse, Partial<IUserReferral> & { referralId: string }>({
			query: ({ referralId, ...body }) => ({ url: `/admin/settings/referrals/${referralId}`, method: 'PUT', body }),
			invalidatesTags: ['GetAllUserReferrals'],
		}),
		getFlashMessages: builder.query<IApiResponse<IFlashNoteMsg[]>, void>({
			query: () => ({ url: '/admin/settings/flash' }),
			providesTags: ['GetFlashMessages'],
		}),
		updateFlashMessage: builder.mutation<IApiResponse<IFlashNoteMsg>, Partial<IFlashNoteMsg>>({
			query: (body) => ({ url: '/admin/settings/flash', method: 'POST', body }),
			invalidatesTags: ['GetFlashMessages'],
		}),
		getFeaturedNotes: builder.query<IApiResponse<IFlashNoteMsg[]>, void>({
			query: () => ({ url: '/admin/settings/notes' }),
			providesTags: ['GetFeaturedNotes'],
		}),
		updateFeaturedNote: builder.mutation<IApiResponse, Partial<IFlashNoteMsg>>({
			query: (body) => ({ url: '/admin/settings/notes', method: 'POST', body }),
			invalidatesTags: ['GetFeaturedNotes'],
		}),
	}),
});

export const {
	useUpdateSettingsMutation,
	useGetAggregatedBanksQuery,
	useUpdateAggregatedBankMutation,
	useUpdateReferralMutation,
	useGetFlashMessagesQuery,
	useUpdateFlashMessageMutation,
	useGetFeaturedNotesQuery,
	useUpdateFeaturedNoteMutation,
} = siteMgtApiSlice;
