import { type DropdownOptionType } from 'BreetComponents';
import type { IFlashNoteMsg, IFlashNoteMsgFeature } from 'BreetHelpers';
import * as yup from 'yup';

export interface FlashNoteBannerItemProps {
	isNoteType?: boolean;
	onClick?: () => void;
}

const featuredNoteLabels: Record<IFlashNoteMsgFeature, string> = {
	sell: 'Sell Note',
	swap: 'Swap Note',
	invoice: 'Invoice Note',
	wallet: 'Wallet Note',
	bills: 'Bills Note',
};

export const reshapedFeaturedNoteData = (data?: Partial<IFlashNoteMsg>[]) =>
	Object.entries(featuredNoteLabels).map(([key, value]) => {
		const currentNote = data?.find((note) => note.feature === key);

		return {
			label: value,
			...currentNote,
			_id: currentNote?._id ?? key,
		};
	});

export const flashMsgSeverityOptions: DropdownOptionType[] = [
	{ id: 'persistent', label: 'Persistent', value: 'persistent' },
	{ id: 'soft', label: 'Soft', value: 'soft' },
];

export const featuredNoteFeatureOptions: DropdownOptionType[] = [
	{ id: 'sell', label: 'Sell', value: 'sell' },
	{ id: 'swap', label: 'Swap', value: 'swap' },
	{ id: 'wallet', label: 'Wallet', value: 'wallet' },
	{ id: 'invoice', label: 'Invoice', value: 'invoice' },
	{ id: 'bills', label: 'Bills', value: 'bills' },
];

export const UpdateFlashMessageSchema = yup.object({
	severity: yup.string().required('Kindly select a severity state'),
	message: yup.string().required('Message is required'),
	time: yup.string().required('Message is required'),
	isActive: yup.bool(),
});

export type UpdateFlashMessageSchemaType = yup.InferType<typeof UpdateFlashMessageSchema>;

export const UpdateFeaturedNoteSchema = yup.object({
	feature: yup.string().required('Kindly select a severity state'),
	message: yup.string().required('Message is required'),
	isActive: yup.bool(),
});

export type UpdateFeaturedNoteSchemaType = yup.InferType<typeof UpdateFeaturedNoteSchema>;
